<template lang="pug">
  form(@submit.stop.prevent="updateCompany()")
    .form-group
      label(for="companyName") Company name
      input.form-control(
        name="companyName"
        v-model="editedCompany.name"
        type="text"
        placeholder="Enter company name"
      )
      small.invalid-feedback.help-block

    .form-group
      label(for="companyWebsite") Company website
      input.form-control(
        name="companyWebsite"
        v-model="editedCompany.website"
        type="text"
        placeholder="Enter company website"
      )
      small.invalid-feedback.help-block  
    
    .form-group
      label(for="aboutCompany") About company
      textarea.form-control(
        name="aboutCompany"
        v-model="editedCompany.description"
        rows=3
        maxlength="360"
        type="text"
        placeholder="Please describe what your company does"
      )
      small.invalid-feedback.help-block
    
    .form-group
      label(for="perks") Perks
      multiselect(
        name="perks"
        v-model="editedCompany.perks",
        :options="$store.state.filterDefaults.perks",
        :multiple="true",
        :close-on-select="false",
        :clear-on-select="false",
        placeholder="Pick perks"
      )

    .form-group
      label(for="GDPR")
      a(:href="editedCompany.gdpr") Download GDPR

    div.text-center
      TheButton(
        :isLoading="isLoading"
        variant="outline-success"
        type="submit"
      )
        | Update
</template>
<script>
import Multiselect from 'vue-multiselect'
import TheButton from '@/components/TheButton'

export default {
    components: {
        Multiselect,
        TheButton,
    },

    props: {
        company: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isLoading: false,
            editedCompany: this.company,
        }
    },

    methods: {
        updateCompany() {
            const { id, name, website, description, perks } = this.editedCompany
            this.isLoading = true

            this.$store.state.backend
                .post('/admin/update-company', {
                    id,
                    name,
                    website,
                    description,
                    perks,
                })
                .then(() => this.showSuccessToast())
                .catch((err) => alert(err))
                .finally(() => (this.isLoading = false))
        },

        showSuccessToast() {
            this.$bvToast.toast('Company successfully updated!', {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: false,
            })
        },
    },
}
</script>
