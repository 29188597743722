<template lang="pug">
  .card
    .card-body
      b-table(
        :items="companies"
        :fields="fields"
        hover
        )
        template(v-slot:cell(active)="data")
          span(:style="{ color: data.item.active ? 'green' : 'red'}")
            | {{data.item.active ? 'Active' : 'Inactive'}}
        
        template(v-slot:cell(action)="row")
          b-button.mr-2(
            @click="row.toggleDetails"
            variant="outline-secondary"
            size="sm"
          ) 
            | Edit

          CompanyToggleActivation(
            :company="row.item"
            @fetch-companies="fetchCompanies($event)"
          )

        template(v-slot:row-details="row")
          CompanyUpdate(
            :company="row.item"
          )
            
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import CompanyToggleActivation from '@/components/CompanyToggleActivation'
import CompanyUpdate from '@/components/CompanyUpdate'
import { STORE_MODULE } from '@/enums'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default {
    computed: {
        ...mapState(['userAccount']),
    },
    components: {
        CompanyToggleActivation,
        CompanyUpdate,
    },
    watch: {
        userAccount: {
            handler(userAccount) {
                if (!!userAccount) {
                    this.fetchCompanies()
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            companies: [],
            fields: [
                { key: 'name', label: 'Company name' },
                { key: 'active_projects' },
                { key: 'active_jobs' },
                { key: 'active', label: 'Status' },
                { key: 'action', thStyle: { width: '200px' } },
            ],
        }
    },
    methods: {
        fetchCompanies(resolve) {
            this.$store.state.backend
                .get('/jobBuilder/company/all')
                .then((ret) => (this.companies = ret.data))
                .catch((error) => alert(error))
                .finally(() => {
                    if (resolve) resolve('resolved')
                })
        },
    },
}
</script>
