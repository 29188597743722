<template lang="pug">
  TheButton(
    :size="size"
    variant="outline-danger"
    @click="toggleActivation()"
    :isLoading="isLoading"
  )
    | {{company.active ? 'Deactivate' : 'Activate' }}

</template>
<script>
import TheButton from '@/components/TheButton'

export default {
    components: {
        TheButton,
    },

    props: {
        company: {
            type: Object,
            required: true,
        },
        size: String,
    },

    data() {
        return {
            isLoading: false,
        }
    },

    methods: {
        toggleActivation() {
            const { id, active } = this.company
            this.isLoading = true

            this.$store.state.backend
                .post('/admin/update-activation/company', {
                    id: id,
                    active: !active,
                })
                .then(() => new Promise((resolve) => this.$emit('fetch-companies', resolve)))
                .catch((err) => this.showErrorToast(err))
                .finally(() => (this.isLoading = false))
        },

        showErrorToast(messageError) {
            this.$bvToast.toast(messageError, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false,
            })
        },
    },
}
</script>
